import { Typography } from "@mui/material";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Typography>Doron</Typography>
    </div>
  );
}

export default App;
